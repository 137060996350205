/* You can add global styles to this file, and also import other style files */
@import "~ng-pick-datetime/assets/style/picker.min.css";

:root {
    --primary: #00B69E !important;
    --primary-lighter: #00B69E26 !important;
    --primary-darker: #04796A !important;
    --primary-darkest: #036155 !important;
}

body {
    // font-family: 'Poppins', sans-serif !important;
    word-break: break-word;
}

.mediadisabled{
    background-color: gray;
}

button.fa {
    display: inline-flex;
    justify-content: space-around;
    align-items: center;
}

.usercontrols {
      font-size: unset !important;
  }
  .usercontrols button {
    background: transparent;
    border: 0px !important;
    outline: 0px !important;
    transition: none;
    display: inline-flex;
    justify-content: space-around;
    align-items: center;
  }
  button.fa-comment {
    background: #fff;
    border-radius: 100%;
    width: 35px;
    height: 35px;
    font-size: 16px;
    margin-right: 10px;
}
button.fa-stack {
    background: #fff;
    border-radius: 100%;
    width: 35px;
    height: 35px;
    font-size: 16px;
    margin-right: 10px;
}
.fa-phone-alt {
    border: 0px;
    outline: 0px;
    background: red !important;
    color: #fff;
    border-radius: 100%;
    width: 35px;
    height: 35px;
    font-size: 14px !important;	
}
button.fa-microphone {
    background: #fff;
    border-radius: 100%;
    width: 35px;
    height: 35px;
    font-size: 16px !important;
}
button.fa-video {
    background: #fff;
    border-radius: 100%;
    width: 35px;
    height: 35px;
    font-size: 16px !important;
    margin-right: 10px;
}
button.fa-desktop {
    background: #fff;
    border-radius: 100%;
    width: 35px;
    height: 35px;
    font-size: 16px !important;
}
button.fa-user {
    background: #fff;
    border-radius: 100%;
    width: 35px;
    height: 35px;
    font-size: 16px !important;
}
button.fa-cog {
    background: #fff !important;
    border-radius: 100%;
    width: 35px;
    height: 35px;
    font-size: 17px !important;
    margin-right: 10px;
}
button.fa-hand-paper {
    background: #fff;
    border-radius: 100%;
    width: 35px;
    height: 35px;
    font-size: 16px !important;
}

.btn {
    border-radius: 5px !important;
    font-weight: 400 !important;
    text-transform: uppercase !important;
}

.btn-primary {
    background-color: var(--primary-darker) !important;
    border-color: var(--primary-darker) !important;

    &:focus, &.focus {
        box-shadow: 0 0 0 0.2rem var(--primary) !important;
    }

    &:hover {
        background-color: var(--primary-darkest) !important;
        border-color: var(--primary-darkest) !important;
    }
}

.videosettingsdiv button {
    border: 0px;
    background: rgba(238, 239, 243, 0.8);
    border-radius: 100%;
    width: 35px;
    height: 35px;
    margin-right: 10px;
    font-size: 14px;
    transition: none;
    display: inline-flex;
    justify-content: space-around;
    align-items: center;
}

.videosettingsdiv button[disabled]{
    color: buttontext;
}


.videosettingsdiv button:last-of-type {
    margin-right: 0;
}

button.focus {
    background: #def8f2 !important;
    outline: 0px !important;
}
.settingsdiv {
    // float: left;
    // width: 100%;
    text-align: center;
    padding: 1rem;
    padding-bottom: 0px;
    // display: inline-block;
}
//change span settings to h3 
.settingsdiv h3 {
    font-size: 15px;
    text-decoration: none !important;
    text-align: center;
}
.settingsdiv-rows {
	// float: left;
	width: 100%;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.settingsdiv-rows-left {
    /* float: left; */
    width: 210px;
    margin-right: .5rem;
    text-align: left;
    padding-right: 5px;
}

.settingsdiv-rows-right {
    /* float: left; */
    width: 180%;
}

.frame {
    border: 1px solid #e1e1e2;
    border-radius: 5px;
}

.settingsdiv-rows-right select {
    width: 100%;
}
.videodiv {
    text-align: center !important;
    float: left;
    width: 100%;
	// background: #fcfcff;
}
.button-wrap {
    float: left;
     width: 100%;
     padding-top: 15px;
     padding-bottom: 15px;
 }
 
 .videosettingsdiv button:not(.mediadisabled) {
    //background: #def8f2 !important;
    background-color: #fff;
    outline: 0px !important;
 }
 .settingsdiv-rows-left p {
     margin-bottom: 10px;
 }
 .screensharediv h1 {
    font-size: 44px;
 }
 button.stop-btn {
    font-size: 18px;
    background: #009776;
    color: #fff;
    border: 0px;
    padding: 10px 15px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    border-radius: 4px;
    top: -20px;
    position: relative;
 }

.settingswidth {
    // max-width: 485px;
    margin-left: auto;
    margin-right: auto;
    display: inline-block;
}

.participantnamediv{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 1.5em;
    text-align: center;
    margin-top: 3px;
    font-size: .9rem;
}


.hostsettings .settingsdiv-rows-left p{
    margin-bottom: 0px;
}

.hostsettings .settingsdiv-rows{
    margin-bottom: 5px;
}

button[disabled]{
    background-color: gray;
}

.bg-grey {
    background-color: #949a9f !important;
}

.inline-block{
    display: inline-block;
}

*{
    transition: all 0.7s ease-out;
}

.dropdown-menu{
    transition: unset;
}

.no-more-video-component-animations{
    width: 100% !important;
    animation: none !important;
}

.volume-meter{
    // height: 100%;
    height: 6px;
    max-width: min(480px, 100%);
    background-color:#85c5b8;
    margin: auto;
    // max-width: 100%;
    transition: all 0.1s;
    width: 0px;
    border-radius: 10px;
    // display: inline-block;
}

video.localparticipant {
    transform: rotateY(180deg);
    -webkit-transform:rotateY(180deg); /* Safari and Chrome */
    -moz-transform:rotateY(180deg); /* Firefox */
  }

  #endMeetingModal .modal-body{
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  .mobile #endMeetingModal .modal-body{
    flex-direction: column;
  }

::-webkit-scrollbar {
    height: 8px;
    width: 8px;
    background: #8b8b8b;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #8b8b8b;
    -webkit-border-radius: 1ex;
    -webkit-box-shadow: 0px 1px 2px #8b8b8b;
  }
  
  ::-webkit-scrollbar-corner {
    background: #8b8b8b;
  }
  
  ::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  .participantbignamediv{
    // display: inline-block;
    width: 100%;
    height: 100%;
    padding: 15px;
    background-color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 10px;
    }

    .participantbignamespan{
        font-size: xxx-large;
        font-weight: 600;
        overflow: hidden;
        text-overflow: ellipsis;
        // text-wrap: balance
    }

    .mobile .participantbignamespan{
        font-size: xx-large;
    }

    .meparticipantcontainerdiv .participantbignamespan, 
    .meparticipantcontainerdiv .usernamespan, 
    .meparticipantcontainerdiv .namespan{
        color: #0d9fa3;
    }

    .participantbignamediv,
    .usernamespan,
    .namespan{
        font-weight: bold;
    }

@media (max-width: 576px) {
    .device-settings-div .settingsdiv-rows {
        flex-direction: column;
        margin-bottom: 10px !important;
    }

    .settingsdiv-rows-left, .settingsdiv-rows-right {
        width: 100%;
        max-width: 100%;
        margin: 0;
    }

    .settingsdiv-rows-right select {
        max-width: 100%;
    }    
}
